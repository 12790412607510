.Logout-Container{

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    width: 100%;
    background-color: white;
    box-shadow: rgb(159 162 191 / 18%) 0px 2px 3px, rgb(159 162 191 / 32%) 0px 1px 1px;

}

.Logout-Container > button{

    margin: 20px 20px 20px 0px;

}
