@import url('https://fonts.cdnfonts.com/css/futura-md-bt');
@font-face {
  font-family: 'font1';
  src: url(/src/assets/fonts/font1_1.otf) format('opentype'), url(/src/assets/fonts/font1_2.ttf) format('truetype');
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

body{
  margin: 0px;
  padding: 0px;
}

a{
  text-decoration: none;
  color: white;
}

@keyframes fadeInFromBottom {
  0% {
    margin: 30px 30px 0px 0px;
  }
  100% {
    margin: 0px;
  }
}

.container-food-menu{
  overflow-x: hidden;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.9s ease-in-out; /* Transición suave */
  display: flex;
}

@media only screen and (min-width: 0px) and (max-width: 670px){
  .fade-in{
    flex-direction: column;
  }
}

@media only screen and (min-width: 671px){
  .fade-in{
    flex-direction: row;
  }
}



.fade-in.visible {
  opacity: 1;
}

.element-with-face-in{
  margin: auto;
  width: 100%;
  height: 600px;
  border: 1px solid black;
  background: linear-gradient(to top, black, #722440);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.container-for-elements-with-face-in{
  display: flex;
  height: 100px;
  flex-direction: column;
  
}

.container-title{
  text-align: center;
  margin-bottom: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.child-title{
  font-family: 'font1', sans-serif;   
  font-style: normal; 
  font-weight: 599;
  font-size: 6rem;
  letter-spacing: 1px;
  text-shadow:1px 1px 0 #000,-1px -1px 0 #000,  1px -1px 0 #000,-1px 1px 0 #000,
  1px 1px 0 #000;
  color: white;
}

.container-link{
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  font-family: 'Futura Md BT', sans-serif;
  font-weight: 550;
}

.child-line{
  border-radius: 2px;
  background-color: white;
  border: 0.1px solid black;
  width: 50%;
  height: 2px;
}

.child-link{
  color: white;
  font-family: "Zen Dots", sans-serif;
  text-shadow:1px 1px 0 #000,-1px -1px 0 #000,  1px -1px 0 #000,-1px 1px 0 #000,
  1px 1px 0 #000;
}