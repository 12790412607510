.Container-Login-Form-Object-Child-Text{
    width: 50%;
    color: #722440;
    font-size: 11px;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
}

.Container-Login-Form-Object-Child-Text:nth-child(1){
    margin: 50px 0px 0px 0px;
}

.Container-Login-Form-Object-Child-Text:nth-child(2){
    margin: 40px 0px 20px 0px;
}

.Container-Login-Form-Object-Child-Text:hover{
    text-decoration: underline;
}