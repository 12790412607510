.App{
  background-color: black;/*tambien cambia el color que ecxiste entre las transiciones de las imagenes del carousel*/
}

a{
    text-decoration: none;
    color: white;
}

.navBar{
    position: absolute;
    z-index: 2;
    display: flex;
    transform: rotate(90deg);
}

.navBarItem{
    margin: 0px 10px 0px 10px;
    font-family: "Zen Dots", sans-serif; 
    color: whitesmoke;
    letter-spacing: 1px;
    font-weight: 550;
    font-size: 1rem;
    text-align: center;
    transition: all ease-in-out 500ms;
    border-left: 2px solid transparent; /* Cambia "black" por el color deseado */
    border-right: 2px solid transparent; /* Cambia "black" por el color deseado */
    border-bottom: 2px solid transparent; /* Cambia "black" por el color deseado */
    cursor: pointer;
}

.navBarItem:hover{
    border-left: 2px solid whitesmoke; /* Cambia "black" por el color deseado */
    border-right: 2px solid whitesmoke; /* Cambia "black" por el color deseado */
    border-bottom: 2px solid whitesmoke; /* Cambia "black" por el color deseado */
}

.navBarItemLink{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 3px 6px 3px 6px;
}

@media only screen and (min-width: 0px) and (max-width: 830px){
    .navBar{
      top: 50%;
      right: -294px;
    }
  }


@media only screen and (min-width: 831px){

  .navBar{
    top: 50%;
    right: -277px;
  }
}

footer{
  display: flex;
  justify-content: center;
  height: 300px;
  background: linear-gradient(to bottom, black, #722440) ! important;
}


.ContainerNavBarInFooter{
  width: auto;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

/*Calendar style*/

@media only screen and (max-width: 1139px){
  #custom-tool-bar{
    display: flex;
    flex-direction: column;
  }
}

.rbc-header{
  color: whitesmoke;
  letter-spacing: 2px;
  font-family: "Zen Dots", sans-serif !important;
}

.rbc-today {
  background-color: #fd798fa4 !important;
}

.rbc-off-range-bg{
  background-color: #784d5f !important;
}

.rbc-off-range-bg:hover{
  background-color: tomato !important;
}

.rbc-off-range > .rbc-button-link{
  color: whitesmoke;
  font-size: 1em;
  font-weight: bold;
}

.rbc-date-cell > .rbc-button-link{
  color: rgba(245, 245, 245, 0.822);
  font-size: 0.8em;
  font-weight: bold;
  font-family: "Zen Dots", sans-serif; 
}

.rbc-toolbar-label{
  color: whitesmoke;
  letter-spacing: 2px;
  font-size: 1.4em;
  font-weight: bold;
  margin: 10px 0px 10px 0px;
  font-family: "Zen Dots", sans-serif; 
}

.rbc-button-link{
  color: whitesmoke;
  letter-spacing: 2px;
  font-size: 0.6em;
  font-family: "Zen Dots", sans-serif;   
}

.rbc-label{
  color: whitesmoke;
  letter-spacing: 2px;
  font-size: 0.6em;
  font-family: "Zen Dots", sans-serif; 
}

.rbc-time-header-gutter{
    width: 61px !important;
    min-width: 61px !important;
    max-width: 61px !important;
}

.rbc-show-more{
  background-color: transparent !important;
  margin-top: 2px !important;
}

.rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
  text-overflow: ellipsis !important;
  white-space: wrap !important;
  width: 100%;
  margin-top: 10px !important;
}



/*datepicker*/
:where(.css-dev-only-do-not-override-nnuwmp).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-nnuwmp).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-nnuwmp).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: linear-gradient(to bottom, black, #722440) ! important;
}

:where(.css-dev-only-do-not-override-nnuwmp).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 1;
  border: 1px solid #722440;
  border-radius: 4px;
  content: "";
}

:where(.css-dev-only-do-not-override-nnuwmp).ant-picker-dropdown .ant-picker-time-panel-column >li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #72244152;
  font-weight: 600;
}

:where(.css-dev-only-do-not-override-nnuwmp).ant-btn-primary {
  color: #fff;
  background: linear-gradient(to bottom, black, #722440) ! important;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

.ant-picker-focused {
  border-color: #722441d8 !important;
  box-shadow: 0 0 0 2px #72244160 !important;
  border-inline-end-width: 1px !important;
  outline: 0 !important;
}