@import url('https://fonts.cdnfonts.com/css/futura-md-bt');
@font-face {
  font-family: 'font1';
  src: url(/src/assets/fonts/font1_1.otf) format('opentype'), url(/src/assets/fonts/font1_2.ttf) format('truetype');
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

a{
  text-decoration: none;
  color: white;
}

.containerSocialNetworks{
  position: absolute;
  transform: translate(0%, -50%);

  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
}

.socialNetworks{
  margin: 10px;
  cursor: pointer;
  transition: all ease-in-out 250ms;
}

.socialNetworks:hover{
  filter: brightness(80%);
}

.socialNetworks:nth-child(1) > div
{
  padding: 15px;
  background: url(/src/assets/images/wslogo.png) no-repeat center;
  background-size: cover;
}

.socialNetworks:nth-child(2) > div{
  padding: 15px;
  background: url(/src/assets/images/flogo.png) no-repeat center;
  background-size: cover;
}

.socialNetworks:nth-child(3) > div{
  padding: 15px;
  background: url(/src/assets/images/inlogo.png) no-repeat center;
  background-size: cover;
}

.socialNetworks:nth-child(4) > div{
  padding: 15px;
  background: url(/src/assets/images/otlogo.png) no-repeat center;
  background-size: cover;
}

#containerMain{
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center; 
}

#video1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  filter: grayscale(0.3);
}

#principalImg1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(0.6);
}

#principalImg2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(0.6);
}

#rectangleIcon {
  position: absolute;
  bottom: 4.5%;
  left: 2.5%;
  width: 13%;
  height: 15%;
  max-width: 100%;
  background-image: linear-gradient(to bottom, #722440, #000000);
  border-radius: 4px;
  z-index: 999;
  animation: expandir-encoger 3.5s forwards;
  transform: scaleX(-1);
  filter: grayscale(0.3);
}

@keyframes expandir-encoger {
  0% {
    max-height: 0%;
    transform: scaleX(1);
  }
  25% {
    max-height: 0%;
    transform: scaleX(1);
  }
  75% {
    max-height: 100%;
    transform: scaleX(-1);
  }
  100% {
    max-height: 0%;
    transform: scaleX(-1);
  }
}


#icon {
  position: absolute;
  z-index: 1;
  bottom: 4.5%;
  left: 3%;
  width: 12%;
  height: 100px;
  background-image: url(/src/assets/images/logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: none;
  transition: opacity 6s ease;
}

#icon.mostrar {
  display: flex;
}

#containerInfoHome{
  /*margin: auto;*/
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spanViewHome1{
  transition: all ease-in-out 100ms;
  font-family: 'font1', sans-serif;
  color: #722440;
  letter-spacing: 1px;
  font-weight:600;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}

.spanViewHome2{
  transition: all ease-in-out 500ms;
  font-family: "Zen Dots", sans-serif; 
  color: whitesmoke;
  letter-spacing: 1px;
  font-weight:300;
  text-align: center;
}

.spanViewHome3{
  transition: all ease-in-out 500ms;
  font-family: "Zen Dots", sans-serif; 
  color: whitesmoke;
  letter-spacing: 1px;
  font-weight: 500;
  text-align: center;
}

.buttonViewHomeGoMenu{
  transition: all ease-in-out 500ms;
  margin-top: 40px;
  width: 40%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 4px solid whitesmoke;
  cursor: pointer;
}

.buttonViewHomeGoMenu:hover{
  background-color: #722440;
}

.spanButtonViewHomeGoMenu {
  width: 100%;
  height: 100%;
  font-family: "Monserrat", sans-serif; 
  color: whitesmoke;
  letter-spacing: 1px;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px; /* Ajusta este valor según tu preferencia */
}

@media only screen and (min-width: 0px) and (max-width: 830px){
  .containerSocialNetworks{
    top: 50%;
    left: 10px;
  }

  #containerInfoHome{
    margin: auto;
    width: 65%;
  }

  .spanViewHome1{
    font-size: 4rem;
  }

  .spanViewHome2{
    font-size: 1.5rem;
  }

  .spanViewHome3{
    font-size: 1rem;
  }

  .spanButtonViewHomeGoMenu{
    font-size: 0.7rem;
  }
}


@media only screen and (min-width: 831px){

  .containerSocialNetworks{
    top: 50%;
    left: 40px;
  }

  #containerInfoHome{
    margin-top: 150px;
    width: 40%;
  }

  .spanViewHome1{
    font-size: 7.5rem;
  }

  .spanViewHome2{
    font-size: 1.5rem;
  }

  .spanViewHome3{
    font-size: 1rem;
  }

  .spanButtonViewHomeGoMenu{
    font-size: 1rem;
  }
}

@keyframes fadeInFromBottom {
  0% {
    margin: 30px 30px 0px 0px;
  }
  100% {
    margin: 0px;
  }
}