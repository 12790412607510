.container-splash-screen{
  position: absolute;
  z-index: 1002;
  width: 100%;
}

.splash-screen {
    min-height: 100vh;
    display: grid;
    justify-content: center;
    align-content: center;
    perspective: 1000px;
    perspective-origin: center top;
    transform-style: preserve-3d;
    overflow: hidden;
    background-color: black;
}

a.circle {
    width: 50vmin;
    height: 50vmin;
    transform-style: preserve-3d;
    position: relative;
    -webkit-animation: h 1s both;
            animation: h 1s both;
    transform-origin: center bottom;
  }
  a.circle:before,
  a.circle:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: center / contain url(/src/assets/images/logo.png);
    background-repeat: no-repeat;
  }
  a.circle:before {
    -webkit-animation: f 4s both;
            animation: f 4s both;
  }
  @-webkit-keyframes f {
    from {
      -webkit-clip-path: inset(0 80% 0 0);
              clip-path: inset(0 80% 0 0);
    }
    50%,
    to {
      -webkit-clip-path: inset(0 0% 0 0);
              clip-path: inset(0 0% 0 0);
    }
  }
  @keyframes f {
    from {
      -webkit-clip-path: inset(0 80% 0 0);
              clip-path: inset(0 80% 0 0);
    }
    50%,
    to {
      -webkit-clip-path: inset(0 0% 0 0);
              clip-path: inset(0 0% 0 0);
    }
  }
  a.circle:after {
    filter: brightness(0.2);
    transform: rotateY(-180deg);
    -webkit-animation: g 4s both;
            animation: g 4s both;
  }
  @-webkit-keyframes g {
    from {
      -webkit-clip-path: inset(0 0 0 20%);
              clip-path: inset(0 0 0 20%);
      transform-origin: 20% 0;
    }
    50%,
    to {
      -webkit-clip-path: inset(0 0 0 100%);
              clip-path: inset(0 0 0 100%);
      transform-origin: 100% 0;
    }
  }
  @keyframes g {
    from {
      -webkit-clip-path: inset(0 0 0 20%);
              clip-path: inset(0 0 0 20%);
      transform-origin: 20% 0;
    }
    50%,
    to {
      -webkit-clip-path: inset(0 0 0 100%);
              clip-path: inset(0 0 0 100%);
      transform-origin: 100% 0;
    }
  }
  @-webkit-keyframes h {
    from {
      transform: rotate(-50deg);
    }
    to {
      transform: rotate(-40deg);
    }
  }
  @keyframes h {
    from {
      transform: rotate(-50deg);
    }
    to {
      transform: rotate(-40deg);
    }
  }  