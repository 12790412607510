.Container-Form-Object-Child-Text{
    margin: 50px 0px 40px 0px;
    width: 50%;
    color: #722440;
    font-size: 11px;
    font-weight: 400;
    text-align: justify;
    text-decoration: none;
}

.Container-Form-Object-Child-Text:hover{
    text-decoration: underline;
}